import { Inject, Injectable } from '@angular/core';
import { GeneralFacade } from './general-state.facade';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG } from 'src/app';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SnackbarService } from '../components/snackbar/snackbar.service';
import { OverlayService } from '../services/overlay/overlay.service';
import { Snackbar } from '../types/snackbar';
import { ErrorPopupComponent } from '../components/error-popup/error-popup.component';
import {
  snackbarObjectGetAccountFailed,
  snackbarObjectGetGenerateFailed,
  snackbarObjectLoginFailed,
} from 'src/app/pages/common/login/components/snackbar/snackbar';
import { LoaderService } from '../components/loader/service/loader.service';
import { UserModel } from 'src/app/pages/common/login/store/models/user.model';
import { CreatorModel } from 'src/app/pages/common/login/store/models/creator.model';
import { catchError, first, tap } from 'rxjs/operators';
import { Item } from '../types/item';
import { AccountManagerModel } from '../types/account-manager';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  private readonly apiUrl: string;
  private isDesktop = new BehaviorSubject<boolean>(true);
  public isDesktop$ = this.isDesktop.asObservable();
  private user = new BehaviorSubject<UserModel>(new UserModel());
  public user$ = this.user.asObservable();
  public userDetailsValue!: UserModel;
  siteReferral: number | null = null;
  public hasPicName = new BehaviorSubject<boolean>(true);
  public hasPicName$ = this.hasPicName.asObservable();
  public creator = new BehaviorSubject<CreatorModel | null>(null);
  public creator$ = this.creator.asObservable();

  public themes = new BehaviorSubject<{ [key: number]: string } | null>(null);
  public themes$ = this.themes.asObservable();

  public ad_tier = new BehaviorSubject<{ [key: number]: string } | null>(null);
  public ad_tier$ = this.ad_tier.asObservable();

  public verticalEnums = new BehaviorSubject<{ [key: number]: string } | null>(
    null
  );
  public verticalEnums$ = this.verticalEnums.asObservable();

  public social_channels = new BehaviorSubject<{
    [key: string]: string;
  } | null>(null);
  public social_channels$ = this.social_channels.asObservable();

  public main_game = new BehaviorSubject<{
    [key: string]: string;
  } | null>(null);
  public main_game$ = this.main_game.asObservable();

  public source_traffic = new BehaviorSubject<{
    [key: string]: string;
  } | null>(null);
  public source_traffic$ = this.source_traffic.asObservable();

  public categories = new BehaviorSubject<Item[] | null>(null);
  public categories$ = this.categories.asObservable();
  servingMethod!: { [key: number]: string };
  adUnitEnum!: { [key: number]: string };
  api_user_token: string | null = null;
  paymentEnum!: { [key: number]: string };

  public adFormats = new BehaviorSubject<{ [key: number]: string } | null>(
    null
  );
  public adFormats$ = this.adFormats.asObservable();

  private accountManager = new BehaviorSubject<AccountManagerModel>(
    new AccountManagerModel()
  );
  public accountManager$ = this.accountManager.asObservable();

  constructor(
    private loginService: LoginService,
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private breakpointObserver: BreakpointObserver,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private loaderService: LoaderService,
    private generalFacade: GeneralFacade
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEnums() {
    const headers = this.loginService.TokenFromStorage;
    const url = `${this.apiUrl}${this.appConfig.actions.enums}`;

    return this.http.get<Array<any>>(url, { headers });
  }

  getAccountManager() {
    const headers = this.loginService.TokenFromStorage;
    const url = `${this.apiUrl}${this.appConfig.actions.account_manager}`;

    return this.http.get(url, { headers }).subscribe(
      (val: any) => {
        let tempAccount: AccountManagerModel = new AccountManagerModel();
        const manager = val.message ? val.message : null;
        if (manager.name) {
          tempAccount.name = manager.name;
        }
        if (manager.mail) {
          tempAccount.mail = manager.mail;
        }
        if (manager.lootlabs_email) {
          tempAccount.mail = manager.lootlabs_email;
        }
        if (manager.pic) {
          tempAccount.pic = manager.pic;
        }
        if (manager.skype) {
          tempAccount.skype = manager.skype;
        }
        if (manager.telegram) {
          tempAccount.telegram = manager.telegram;
        }
        if (manager.discord) {
          tempAccount.discord = manager.discord;
        }
        this.accountManager.next(tempAccount);
      },
      catchError((error: any) => {
        this.loaderService.hide();
        return this.catchError(snackbarObjectGetAccountFailed, error);
      })
    );
  }

  loadLootLabsData() {
    return forkJoin([this.getEnums()]);
  }

  loadServingMethodEnum() {
    this.generalFacade.enums$.pipe(first((val) => !!val)).subscribe((state) => {
      this.servingMethod = state.message.serving_method_id || {};
    });
  }

  getAdFormat() {
    this.generalFacade.enums$.pipe(first((val) => !!val)).subscribe((state) => {
      const adFormatsEnum = state.message.ad_formats;
      this.adFormats.next(adFormatsEnum);
    });
  }

  getServingMethod(key: number) {
    if (!this.servingMethod) {
      this.loadServingMethodEnum();
    }
    if (this.servingMethod && this.servingMethod.hasOwnProperty(key)) {
      return this.servingMethod[key];
    } else {
      return null;
    }
  }

  loadAdUnitEnum() {
    this.generalFacade.enums$.pipe(first((val) => !!val)).subscribe((state) => {
      this.adUnitEnum = state.message.ad_unit || {};
    });
  }

  getAdUnit(key: number) {
    if (!this.adUnitEnum) {
      this.loadAdUnitEnum();
    }

    if (this.adUnitEnum.hasOwnProperty(key)) {
      return this.adUnitEnum[key];
    } else {
      return null;
    }
  }

  loadVerticalEnum() {
    this.generalFacade.enums$.pipe(first((val) => !!val)).subscribe((state) => {
      this.verticalEnums.next(state.message.verticals || {});
    });
  }
  geCreatorDetails() {
    return this.creator.value;
  }

  getUserDetails() {
    const url = this.apiUrl + this.appConfig.actions.user;
    const headers = this.loginService.TokenFromStorage;

    return this.http.get(url, { headers }).pipe(
      tap((res: any) => {
        this.user.next(res.message);
        this.userDetailsValue = res.message;
        this.api_user_token = res.message.api_user_token;
      }),
      catchError((error: any) => {
        this.loaderService.hide();
        return this.catchError(snackbarObjectLoginFailed, error);
      })
    );
  }

  getCreator(): Observable<any> {
    const urlCreator = this.apiUrl + this.appConfig.actions.creator;

    const headers = new HttpHeaders({
      Authorization: `${
        this.loginService.token || localStorage.getItem('authToken')
      }`,
    });

    return this.http.get(urlCreator, { headers }).pipe(
      tap((creator: any) => {
        if (creator && creator.message) {
          if (creator.message.creator_name && creator.message.creator_picture) {
            localStorage.setItem('hasDetails', 'true');
            localStorage.setItem('creatorName', creator.message.creator_name);
            localStorage.setItem('creatorPic', creator.message.creator_picture);
            this.hasPicName.next(true);
          } else {
            localStorage.setItem('hasDetails', 'false');
            this.hasPicName.next(false);
          }
          this.creator.next(creator.message);
        }
      })
    );
  }

  loadPaymentEnum() {
    this.generalFacade.enums$.pipe(first((val) => !!val)).subscribe((state) => {
      this.paymentEnum = {
        ...state.message.payment_methods.basic,
        ...state.message.payment_methods.bitcoin,
      };

      return this.paymentEnum;
    });
    return {};
  }

  getPaymentValue(key: string) {
    if (!this.paymentEnum) {
      this.loadPaymentEnum();
    }
    const keyNum = parseInt(key);
    if (this.paymentEnum && this.paymentEnum.hasOwnProperty(keyNum)) {
      return this.paymentEnum[keyNum];
    }

    return '';
  }

  getHasPicName() {
    return this.hasPicName.value;
  }

  responsive() {
    this.breakpointObserver
      .observe(['(min-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        this.isDesktop.next(state.matches);
      });
  }

  openErrorPopUp() {
    this.overlayService.open(ErrorPopupComponent, {}, { disableClose: true });
  }

  catchError(snackbarObject: Snackbar, error: any) {
    if (error.status === 401) {
      this.openErrorPopUp();
    } else {
      this.snackbarService.showSnackbar(snackbarObject);
    }
    return error;
  }

  setUser(updatedUser: Partial<UserModel>) {
    const currentUser = this.user.getValue();

    const mergedUser: UserModel = {
      ...currentUser,
      ...updatedUser,
    };

    this.user.next(mergedUser);
  }

  getUser() {
    return this.user.value;
  }

  getSiteReferral() {
    const headers = this.loginService.TokenFromStorage;
    const url = `${this.apiUrl}${this.appConfig.actions.site}${this.appConfig.params.referral}`;

    return this.http.get(url, { headers }).subscribe(
      (val: any) => {
        if (val.message) {

          this.siteReferral = val.message;
        }
      },
      catchError((error: any) => {
        this.loaderService.hide();
        return this.catchError(snackbarObjectGetGenerateFailed, error);
      })
    );
  }

  getThemesFromEnum(): void {
    this.generalFacade.enums$.pipe(first((val) => !!val)).subscribe((state) => {
      this.themes.next(state.message.theme_ids || {});
    });
  }

  getSocialChanneFromEnum() {
    this.generalFacade.enums$.pipe(first((val) => !!val)).subscribe((state) => {
      this.social_channels.next(state.message.social_channel_titles || {});
    });
  }

  loadMainGameFromEnums() {
    return this.generalFacade.enums$.pipe(first((val) => !!val)).subscribe((state) => {
      this.main_game.next(state.message.main_game || {});
    });
  }

  loadMainTrafficFromEnums() {
    return this.generalFacade.enums$.pipe(first((val) => !!val)).subscribe((state) => {
      this.source_traffic.next(state.message.source_traffic || {});
    });
  }

  getSocialAsArray() {
    const obj = this.social_channels.value;
    if (obj) {
      return Object.entries(obj).map(([key, value]) => ({ key, value }));
    }
    return [];
  }

  getThemeAsArray() {
    const obj = this.themes.value;
    if (obj) {
      return Object.entries(obj).map(([key, value]) => ({ key, value }));
    } else {
      return [];
    }
  }

  getTierAsArray() {
    const obj = this.ad_tier.value;
    if (obj) {
      return Object.entries(obj).map(([key, value]) => ({ key, value }));
    } else {
      return [];
    }
  }

  getVerticalList() {
    if (!this.verticalEnums) {
      this.loadVerticalEnum();
    }

    return Object.entries(this.verticalEnums)
      .filter(([key]) => key !== '171')
      .map(
        ([key, value]) =>
          ({
            key: isNaN(Number(key)) ? key : Number(key),
            value,
          } as Item)
      );
  }

  getThemes(key: number | null): string | null {
    const themes = this.themes.getValue();
    if (!themes) {
      return null;
    }

    if (themes && key && themes.hasOwnProperty(key.toString())) {
      return themes[key];
    } else {
      return null;
    }
  }
  getAdTierFromEnum() {
    this.generalFacade.enums$.pipe(first((val) => !!val)).subscribe((state) => {
      this.ad_tier.next(state.message.tier_ids || {});
    });
  }

  getAdTier(key: number | null) {
    const ad_tier = this.ad_tier.getValue();

    if (!ad_tier) {
      return null;
    }
    if (ad_tier && key && ad_tier.hasOwnProperty(key.toString())) {
      return ad_tier[key];
    } else {
      return null;
    }
  }

  getVertical(key: number | null) {
    const vertical = this.verticalEnums.getValue();

    if (!vertical) {
      return '';
    }
    if (vertical && key && vertical.hasOwnProperty(key.toString())) {
      return vertical[key];
    } else {
      return '';
    }
  }

  getCategories(key: number | null) {
    const categories = this.categories.getValue();
    if (!categories) {
      this.getCategoriesEnum();
    }
    if (categories && key && this.categories.hasOwnProperty(key)) {
      return categories[key];
    } else {
      return null;
    }
  }

  getCategoriesEnum() {
    this.generalFacade.enums$.pipe(first((val) => !!val)).subscribe((state) => {
      this.categories.next(state.message.link_categories || {});
    });
  }

  getCategegoriesArray() {
    const categoriesMap = this.categories.getValue();
    if (categoriesMap) {
      const categories = Object.entries(categoriesMap).map(([key, value]) => ({
        key,
        value: typeof value === 'string' ? value : value.value,
      }));
      return categories;
    }
    return [];
  }

  loadEnums() {
    this.loadVerticalEnum();
    this.getThemesFromEnum();
    this.getAdTierFromEnum();
    this.getCategoriesEnum();
    this.getAdFormat();
    this.loadMainGameFromEnums();
    this.loadMainTrafficFromEnums();
  }
}
