<div class="container background popupWith">
  <div class="header">
    <span class="typography-title">Attention</span>
    <app-icon class="close" name="close" (click)="close()"></app-icon>
  </div>
  <div class="copy-frame-container">
    <span class="subtitle">Bi-weekly payments will be sent on Sunday or Monday. <br> We apologize for the inconvenience.</span>

  </div>

</div>
