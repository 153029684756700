import { FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

export function replaceSpacesWithUnderscores(inputString: string): string {
  return inputString.replace(/ /g, '_');
}
export function createFullName(
  firstName: string | null,
  lastName: string | null
) {
  if (firstName) {
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  }
  return `${firstName} ${lastName}`;
}

export function loadCountries(state: any) {
  const countryObj = state.message.countries_map;

  return transformCountriesMap(countryObj);
}

export function formatCurrency(number: number): string {
  const formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);

  return formattedNumber;
}

export function formatDateTime(
  inputDate: string | number,
  hours = true
): string {
  const dateObject = new Date(inputDate);

  const day = dateObject.getUTCDate().toString().padStart(2, '0');
  const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getUTCFullYear();
  const hour = dateObject.getUTCHours().toString().padStart(2, '0');
  const minute = dateObject.getUTCMinutes().toString().padStart(2, '0');

  if (!hours) {
    return `${day}/${month}/${year}`;
  }

  return `${day}/${month}/${year}, ${hour}:${minute}`;
}

function transformCountriesMap(countriesMap: any) {
  const result: any[] = [];

  for (const code in countriesMap) {
    if (countriesMap.hasOwnProperty(code) && code !== '') {
      const countryName = countriesMap[code].country_name;
      result.push({ key: code, value: countryName });
    }
  }

  result.sort((a, b) => {
    const nameA = a.value?.toUpperCase();
    const nameB = b.value?.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return result;
}

export function capitalizeFirstLetter(inputStr: string): string {
  if (inputStr && inputStr[0].toLowerCase() === inputStr[0]) {
    return inputStr[0].toUpperCase() + inputStr.slice(1);
  }
  return inputStr;
}

export function removeUnderLine(enumName: string): string {
  return enumName.replace(/_/g, ' ');
}

export function formatDate(inputDate: string): string {
  const date = new Date(inputDate);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const formattedDate = `${day}/${month}/${year}`;
  const formattedTime = `${hours}:${minutes}`;

  return `${formattedDate}, ${formattedTime}`;
}

export function getValueByKey(
  obj: { [key: string]: string },
  key: string | number | null
): string | undefined {
  if (key === '') {
    return '';
  } else if (key != null) {
    return obj[key];
  } else return;
}

export function extractIds(data: string[]): string[] {
  return data.map((obj: any) => obj.id);
}

export function convertFromValueToKey(
  data: { key: string; value: string }[],
  value: string
) {
  const selectedKey = data.find((option) => option.value === value)?.key;

  return Number(selectedKey);
}

export function checkCategoryValidation(value: string, form: FormGroup) {
  if (value !== 'Create Folder') {
    form.get('other_category')?.reset();
    form.get('other_category')?.clearValidators();
    form.get('other_category')?.updateValueAndValidity();
  } else {
    form.get('other_category')?.setValidators([Validators.required]);
    form.get('other_category')?.updateValueAndValidity();
  }
}

export function formatDateRange(dateRange: {
  startDate: any;
  endDate: any;
}): { from_date: string; to_date: string } | null {
  if (dateRange && dateRange.startDate && dateRange.endDate) {
    const formattedStartDate = dateRange.startDate.format('YYYY-MM-DD');
    const formattedEndDate = dateRange.endDate.format('YYYY-MM-DD');

    return {
      from_date: formattedStartDate,
      to_date: formattedEndDate,
    };
  }
  return null;
}

export function objectToArray(obj: { [key: string]: string }) {
  return Object.entries(obj).map(([key, value]) => ({ key, value }));
}

export function reduceColumn(columnName: string, rows: any) {
  let sum = 0;
  for (const row of rows) {
    const value = row[columnName];
    if (value && value !== 'undefined') {
      let cleanedValue = value.trim();
      if (cleanedValue.startsWith('$')) {
        cleanedValue = cleanedValue.slice(1);
      }
      if (cleanedValue.endsWith('%')) {
        cleanedValue = cleanedValue.slice(0, -1);
      }
      cleanedValue = parseFloat(cleanedValue.replace(/,/g, ''));

      sum += cleanedValue;
    }
  }
  if (columnName === 'CPM' || columnName === 'Sessions CPM' || columnName === 'CPC') {
    sum = sum / rows.length;
  }

  return sum;
}

export function increment(currentValue: number, maxNumber: number) {
  if (currentValue < maxNumber) {
    const updateValue = currentValue + 1;
    return updateValue;
  } else return currentValue;
}

export function decrement(currentValue: number, minNumber: number) {
  if (currentValue > minNumber) {
    const updateValue = currentValue - 1;
    return updateValue;
  } else return currentValue;
}

export function capitalizeFirstLetterOtherLow(inputString: string): string {
  if (!inputString) {
    return inputString;
  }

  const firstLetter = inputString.charAt(0).toUpperCase();
  const restOfString = inputString.slice(1).toLowerCase();

  return firstLetter + restOfString;
}

export function convertDateToEpoch(dateString: string): number {
  const epochTime = moment(dateString, 'YYYY-MM-DD,HH:MM').valueOf() / 1000;
  return epochTime;
}

export function paginationDropDown(
  numOfPages: number,
  limitPerPage: number
): number[] {
  const array: number[] = [];
  let jump = limitPerPage;

  for (let i = 0; i < numOfPages; i++) {
    if (jump > 50) {
      break;
    }
    array.push(jump);
    jump += limitPerPage;
  }

  return array;
}
