import { Component, Input, OnChanges } from '@angular/core';
import { AccountService } from './services/account.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PersonalEnum } from './enum/personalForm.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { createFullName } from 'src/app/shared/helper/helper';
import { Regex } from 'src/app/shared/validators/regex';
import { forkJoin } from 'rxjs';
import { UserModel } from 'src/app/pages/common/login/store/models/user.model';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { NotificationType } from 'src/app/shared/components/notifications/models/notifications.model';
import { NotificationsService } from 'src/app/shared/components/notifications/services/notifications.service';

@Component({
  selector: 'app-personal-settings',
  templateUrl: './personal-settings.component.html',
  styleUrls: ['./personal-settings.component.scss'],
})
export class PersonalSettingsComponent implements OnChanges {
  @Input() userDetails!: UserModel;
  isButtonDisabled = true;
  public accountForm!: FormGroup;
  constructor(
    private accountService: AccountService,
    private loginService: LoginService,
    private generalService: GeneralService,
    private notificationService: NotificationsService
  ) {}
  selectedTab = PersonalEnum.GeneralInfo;

  ngOnChanges(): void {
    const currentState = this.userDetails.state ?? '';

    forkJoin([
      this.accountService.loadCountries(),
      this.accountService.loadContactPlatform(),
      this.accountService.loadStates(this.userDetails.country),

      this.accountService.loadCities(this.userDetails.country, currentState),
    ]);

    this.initForm();

    this.accountForm.valueChanges.subscribe(() => {
      if (this.accountForm.valid) {
        this.isButtonDisabled = false;
      }
    });
  }

  changeTab(event: MatTabChangeEvent) {
    this.selectedTab = event.tab.textLabel as PersonalEnum;
  }

  initForm() {
    this.accountForm = new FormGroup({
      general_info: new FormGroup({
        full_name: new FormControl(
          createFullName(
            this.userDetails.first_name,
            this.userDetails.last_name
          ) || null,
          [
            Validators.required,
            // Validators.pattern(Regex.stringRegex),
            Validators.minLength(2),
          ]
        ),
        username: new FormControl(
          { value: this.userDetails.username || '', disabled: true },
          [Validators.required, Validators.email]
        ),
        signup_contact_id: new FormControl(
          this.userDetails.signup_contact_id || '',

          [Validators.minLength(2)]
        ),
        signup_contact_type: new FormControl(
          this.userDetails.signup_contact_type || ''
        ),
        company_name: new FormControl(this.userDetails.company_name || ''),
        safety_number: new FormControl(this.userDetails.safety_number || '', [
          Validators.minLength(2),
        ]),
      }),
      address: new FormGroup({
        country: new FormControl(this.userDetails.country || '', [
          Validators.required,
        ]),
        state: new FormControl(this.userDetails.state || ''),
        city: new FormControl(this.userDetails.city || ''),
        street: new FormControl(this.userDetails.street || '', [
          Validators.minLength(2),
        ]),
        zip_code: new FormControl(this.userDetails.zip_code || '', [
          Validators.minLength(2),
        ]),
      }),
    });
  }

  submit() {
    this.isButtonDisabled = true;
    const body = {
      ...this.accountForm.get('general_info')?.value,
      ...this.accountForm.get('address')?.value,
    };


    const notification = {
      notificationType: NotificationType.PersonalDetails,
    };

    const newNotification =
      this.notificationService.createNotificationObject(notification);

    this.notificationService
      .createNotification(newNotification)
      .subscribe(() => {
        this.notificationService.getNotifications();
      });

    const fullNameParts = body.full_name.split(' ');
    body.first_name = fullNameParts[0];
    body.last_name = fullNameParts.slice(1).join(' ');
    delete body.full_name;
    this.loginService.updateUser(body).subscribe(() => {
      this.generalService.setUser(body);
    });
  }
}
